import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-header-waitress',
  templateUrl: './header-waitress.component.html',
  styleUrls: ['./header-waitress.component.scss']
})
export class HeaderWaitressComponent implements OnInit {
  public loader: boolean = false;

  constructor(
    private authService: AuthService
  ) { }

  ngOnInit(): void {
  }

  public onLogout() {
    this.loader = true;
    this.authService.logout();
    window.setTimeout(() => {
      this.loader = false;
    }, 1000);
  }

}
