<section class="cv-input-code">
  <form class="cv-input-code-form cv-input-code-form-input cv-inputs" [formGroup]="codeForm">
      <div class="cv-input-code-titule"> {{"PARTNER.numberFolio" | translate}} </div>
      <div class="cv-input-code-text"> {{"PARTNER.inputQR" | translate}} </div>
      <mat-form-field appearance="outline">
          <input class="" matInput type="text" formControlName="code">
          <mat-error class="TxtCaptionColorErrorMediumLeft12Textfield" *ngIf="codeForm.get('code')?.hasError('pattern')">{{'AUTH.errorCode' | translate}}</mat-error>
          <mat-error class="TxtCaptionColorErrorMediumLeft12Textfield" *ngIf="codeForm.get('code')?.hasError('required')">{{'AUTH.errorRequiredField' | translate}}</mat-error>
      </mat-form-field>
      <button (click)="closeModal()" class="cv-primary-button" type="submit" mat-flat-button [disabled]="codeForm.invalid">{{'PARTNER.intro' | translate}}</button>
  </form>
</section>
<div *ngIf="loader" class="cava-loading">
  <app-loader></app-loader>
</div>
