import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { firstValueFrom } from 'rxjs';
import { SessionStatus } from '../shared/models/partner-model';
import { MatSnackBar } from '@angular/material/snack-bar';


@Injectable({
  providedIn: 'root'
})
export class PartnerService {
  public url = environment.rootUrl;
  public accesstoken = localStorage.getItem('adminToken');
  public partnerAccessToken = localStorage.getItem('partnerToken');
  public headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${this.accesstoken}`
  });
  public headers2 = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${this.partnerAccessToken}`
  });
  constructor(
    private router: Router,
    private http: HttpClient,
    private snack: MatSnackBar,
  ) { }

  public partnerSearch(userPartnerNumberOrName: any , page: any) {
    try {
      return this.http.get(`${this.url}/users?search=${userPartnerNumberOrName}&page=${page}&size=30`, {}).toPromise();
    } catch (error) {
      return error
    }
  }

  public getPartnerbyId(idUserPartner: any, productTypeName: any) {
    try {
      return this.http.get(`${this.url}/users-partner/details?idUserPartner=${idUserPartner}&productTypeName=${productTypeName}&page=1&size=12`, { headers: this.headers }).toPromise();
    } catch (error) {
      return error
    }
  }

  public loginPartner(body: any): any{
    try {
      return firstValueFrom(this.http.post(`${this.url}/consumption/login`, body));
    } catch (error) {
      return error
    }
  }

  public async loginPartnerQR(qr: string ): Promise<any>{
    const idMesero = localStorage.getItem('admin_id');
    const body = {
      folio: qr,
      user_service_id: idMesero
    }
    return await firstValueFrom(this.http.post(`${this.url}/consumption/login-qr`, body)).then( ress =>{
      this.snackBarServicePreConsumer('Bienvenido a Cava Virtual');
      return ress;
    }
    ).catch( error => {
      this.snackBarServicePreConsumer('El código QR escaneado es incorrecto');
      return error
    });
  }

  public async logoutPartnerQR(qr: string ): Promise<any>{
    const body = {
      folio: qr
    }
    return await firstValueFrom(this.http.post(`${this.url}/consumption/logout-qr`, body)).then(ress => {
      this.snackBarServicePreConsumer('Gracias por usar Cava Virtual');
      return ress;
    }).catch( error => {
      this.snackBarServicePreConsumer('El código QR escaneado es incorrecto');
      return error
    });
  }

  public sessionStatus(userID: number): Promise<SessionStatus>{
    return firstValueFrom(this.http.get<SessionStatus>(`${this.url}/consumption/session-status?user_id=${userID}`));
  }

  public closeCava() {
    localStorage.removeItem('partnerToken')
  }

  public snackBarServicePreConsumer(text: string){
    this.snack.open( text , '', {
      verticalPosition: 'bottom',
      horizontalPosition: 'center',
      duration: 5000,
      panelClass: 'cv-snackbar-create'
    });
  }
}
