export const DEFAULT_LANGUAGE = 'es';
export const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@']+(\.[^<>()\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const MIN_PW = 8;
export const MAX_PW = 16;
export const MIN_PIN = 4;
export const MAX_PIN = 4;
// TODO: DISABLE FOR THE MOMENT
// *******************
// export const URL_IMG: string = "https://cavavirtual-cdn-prod.s3.us-east-2.amazonaws.com/";
export const URL_IMG: string = "https://cavavirtual-cdn-dev.s3.us-west-2.amazonaws.com/";
