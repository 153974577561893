import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CavaService {

  public url = environment.rootUrl;
  public accesstoken = localStorage.getItem('partnerToken');
  public headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${this.accesstoken}`
  });
  constructor(
    private http: HttpClient
  ) { }

  public async getCavaPartner(idUserPartner: any, establishmentId: any): Promise<any> {
    try {
      return this.http.get(`${this.url}/consumption/user-cava?user_id=${idUserPartner}&establishment_id=${establishmentId}`,
       { headers: this.headers }).toPromise();
    } catch (error) {
      return error
    }
  }

  public async createConsumption(body: any) {
    try {
      return this.http.post(`${this.url}/consumption/set-consumption`, body).toPromise();
    } catch (error) {
      return error
    }
  }

  public async upDataConsumption(body: any , preConsumptionId: any ) {
    try {
      return this.http.patch(`${this.url}/consumption/update-consumption/${preConsumptionId}`,
      body , {headers: this.headers} ).toPromise();
    } catch (error) {
      return error
    }
  }

  public async getConsumption(userId: any , establishmentId: any ): Promise<any>{
    try {
      return this.http.get(`${this.url}/consumption?user_id=${userId}&establishment_id=${establishmentId}`,
       ).toPromise();
    } catch (error) {
      return false;
    }
  }

  public async cancelService(body: any): Promise<any>{
    try {
      return this.http.post(`${this.url}/consumption/cancel-service `,
      body , {headers: this.headers} ).toPromise();
    } catch (error) {
      return false;
    }
  }

  public async closeCava(body: any  ): Promise<any>{
    try {
      return this.http.post(`${this.url}/consumption/close`,
      body , {headers: this.headers} ).toPromise();
    } catch (error) {
      return false;
    }
  }
}
