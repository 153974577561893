<body class="cv-headers-cava">
  <div class="cv-headers-cava-text">
    <div class="cv-flex cv-w-33">
      <img src="{{ this.logo }}" alt="" />
      <div class="cv-time">
        {{ minutes < 10 ? 0 : "" }} {{ minutes }} : {{ count < 10 ? 0 : "" }}
        {{ count }}
      </div>
      <samp class="cv-close-text">Tiempo para cerrar la cava</samp>
    </div>
    <h1 class="TxtH1WhiteRegularLeft942 cv-headers-cava-text-user cv-w-33">
      {{ partnerName }}
    </h1>

    <div class="cv-w-33 cv-close">
      <a
        *ngIf="offClose"
        class="TxtButtonWhiteSemiboldUnderlinedCenter142 cv-headers-cava-text-close"
        (click)="closeCava()"
        >{{ "HEADER.headerCava" | translate }}</a
      >
    </div>
  </div>
</body>
