import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header-login',
  templateUrl: './header-login.component.html',
  styleUrls: ['./header-login.component.scss']
})
export class HeaderLoginComponent implements OnInit {
  public loader: boolean = false;

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  public onClose() {
    this.loader = true;
    this.router.navigate([`/partners/search`]).then(() => {
      document.documentElement.scrollTop = 0;
    });
    window.setTimeout(() => {
      this.loader = false;
    }, 500);
  }

}
