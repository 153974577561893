import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-input-code',
  templateUrl: './input-code.component.html',
  styleUrls: ['./input-code.component.scss']
})
export class InputCodeComponent implements OnInit {

  public codeForm: FormGroup;
  public loader = false;

  constructor(
    public dialogRef: MatDialogRef<InputCodeComponent>,
  ) {
    this.codeForm = new FormGroup({
      code: new FormControl('', [Validators.required, Validators.minLength(5) , Validators.maxLength(6)])
    })
  }


  ngOnInit(): void {
  }


  public closeModal(): void {
    if (this.codeForm.status != 'INVALID') {
      this.dialogRef.close(this.codeForm.value);
    } else {
      this.dialogRef.close(null);
    }
  }
}
