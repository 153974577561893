import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';

export type LoginResponse = {
  token: string;
  user_service_id: number;
  email: string;
};

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public url = environment.rootUrl;
  public accesstoken = localStorage.getItem('adminToken');
  public user: any;

  constructor(private router: Router, private http: HttpClient) {}

  public login(code: string) {
    return this.http.post<{ data: LoginResponse }>(
      `${this.url}/user-service/login-code`,
      {
        password: code,
      }
    );
  }

  public checkStatusResetRequest(token: any) {
    try {
      return this.http
        .post(
          `${this.url}/auth/check-generate-login-service?token=${token}`,
          null
        )
        .toPromise();
    } catch (error) {
      return error;
    }
  }

  public updatePassword(newPassword: any, waiterId: any) {
    try {
      return this.http
        .patch(
          `${this.url}/auth/change-pwd-service/?userServiceId=${waiterId}`,
          newPassword
        )
        .toPromise();
    } catch (error) {
      return error;
    }
  }

  public logout() {
    this.accesstoken = localStorage.getItem('adminToken');
    if (this.accesstoken) {
      localStorage.clear();
      this.router.navigate(['auth']);
    } else {
      localStorage.clear();
      this.router.navigate(['auth']);
    }
  }

  get getUser(): any {
    return (this.user = localStorage.getItem('adminToken'));
  }

  get isLogged(): boolean {
    const user = this.getUser;
    return user !== null;
  }
}
