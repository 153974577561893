<div class="cv-close">
  <p class="cv-close-titule">{{'CAVA.closeResumeTitule'| translate}}</p>

  <mat-icon class="cv-close-icon" svgIcon="ic-cava-akar-icons"></mat-icon>

  <p class="cv-close-text">
    {{'CAVA.closeResumetext'| translate}}
  </p>

  <button (click)="closeModal()" mat-button class="cv-close-button" >
    {{'CAVA.closeResume'| translate}}
  </button>

</div>
