export const environment = {
  production: true,
  rootUrl: 'https://multicava-api.cavavirtual.com.mx/api-v1/',
  firebase: {
    apiKey: "AIzaSyD6u7IqnUR5oDQ5lv0z7T9xjg73a5HBJOs",
    authDomain: "cava-virtual.firebaseapp.com",
    projectId: "cava-virtual",
    storageBucket: "cava-virtual.appspot.com",
    messagingSenderId: "732800470930",
    appId: "1:732800470930:web:3bb104fd4d5f03a4ee7b4b"
  }
};
