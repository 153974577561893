<body class="cv-headers-waitress">
    <mat-icon class="cv-logo-icon cava-icon-white" svgIcon="ic-logos-brand-cava-virtual_logo"></mat-icon>
    <div class="cv-headers-waitress-text">
        <h1 class="TxtH1WhiteRegularLeft942 cv-headers-waitress-text-user">{{'HEADER.headerCavaWaitress' | translate}}</h1>
    </div>
    <mat-icon class="cv-logout" svgIcon="ic-control-white-logout" (click)="onLogout()"></mat-icon>
</body>
<div *ngIf="loader" class="cava-loading cava-loading-opacity">
    <app-loader></app-loader>
</div>
