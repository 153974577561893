import { CloseCavaComponent } from 'src/app/cava/close-cava/close-cava.component';
import { MatDialog } from '@angular/material/dialog';
import { PartnerService } from 'src/app/services/partner.service';
import { CavaService } from 'src/app/services/cava.service';
import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import {
  Subject,
  Subscription,
  interval,
  startWith,
  takeUntil,
  tap,
} from 'rxjs';
import { SubSink } from 'subsink';
import { Location } from '@angular/common';

@Component({
  selector: 'app-header-cava',
  templateUrl: './header-cava.component.html',
  styleUrls: ['./header-cava.component.scss'],
})
export class HeaderCavaComponent implements OnInit {
  public partnerName: string = '';
  public logo: string = '';
  public cavaEmpty: boolean = false;
  public counterSubscription: Subscription;
  public minutes = 15;
  public count = 59;
  public offClose = true;
  private reset$ = new Subject<void>();
  private destroy$ = new Subject<void>();
  public subscriptions = new SubSink();

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private cavaService: CavaService,
    private location: Location,
    private partnerService: PartnerService
  ) {
    this.subscriptions.add(
      router.events.subscribe((val: any) => {
        if (val instanceof NavigationEnd) {
          if (location.path().includes('cava/cava-resume')) {
            this.offClose = false;
          } else {
            this.offClose = true;
          }
          console.log(this.offClose);
        }
      })
    );

    const count = localStorage.getItem('count');
    const minutes = localStorage.getItem('minutes');
    this.count = parseInt(count ?? '59', 10);
    this.minutes = parseInt(minutes ?? '15', 10);

    this.counterSubscription = interval(1000)
      .pipe(
        startWith(0),
        takeUntil(this.reset$),
        takeUntil(this.destroy$),
        tap(() => {
          if (this.count == 0) {
            this.count = 59;
            this.minutes--;
          } else {
            this.count--;
          }
          localStorage.setItem('count', this.count.toString());
          localStorage.setItem('minutes', this.minutes.toString());

          if (this.minutes == 0 && this.count == 0) {
            localStorage.clear();
            localStorage.removeItem('count');
            localStorage.removeItem('minutes');

            this.router.navigate([`auth`]).then(() => {
              document.documentElement.scrollTop = 0;
            });
          }
        })
      )
      .subscribe();
  }

  ngOnInit(): void {
    if (this.location.path().includes('cava/cava-resume')) {
      this.offClose = false;
    } else {
      this.offClose = true;
    }
    this.onGetPartnerById();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    if (this.counterSubscription) {
      this.counterSubscription.unsubscribe();
    }
  }

  public async onGetPartnerById() {
    let partner = await JSON.parse(`${localStorage.getItem('partnerData')}`);
    this.logo = partner.data.user_service.logo;
    this.partnerName = `${partner.data.user.name} ${partner.data.user.surnames}`;
  }

  public async closeCava() {
    this.router.navigate([`cava/cava-resume`]).then(() => {
      document.documentElement.scrollTop = 0;
    });
  }
}
