import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-open-wine',
  templateUrl: './modal-open-wine.component.html',
  styleUrls: ['./modal-open-wine.component.scss']
})
export class ModalOpenWineComponent implements OnInit {

  constructor(
    public dialogCole: MatDialogRef<ModalOpenWineComponent>,
  ) { }

  ngOnInit(): void {
  }

  public closeModal(value : boolean): void {
    this.dialogCole.close( value );
  }

}
