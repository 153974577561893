import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class InterceptorHeaderInterceptor implements HttpInterceptor {

  constructor() {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const requestAux = this.addToken(request)
    return next.handle(requestAux);
  }

  public addToken(request: HttpRequest<unknown>): any{
    const token = localStorage.getItem('adminToken');

    if (token){
      const authRes = request.clone({
        headers: request.headers.set('Authorization', 'Bearer ' + token )
      });
       return authRes;
    }

    return request;
  }
}
