import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { DEFAULT_LANGUAGE } from 'src/app/shared/utils';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';
import { Component, ElementRef } from '@angular/core';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'CavaVirtualMesero';
  public cava: boolean = false;
  public empty: boolean = false;
  public waitress: boolean = false;
  public subscriptions = new SubSink();
  public partnersLogin: boolean = false;

  constructor(
    private sanitizer: DomSanitizer,
    private router: Router,
    private location: Location,
    private translate: TranslateService,
    private iconRegistry: MatIconRegistry
  ) {
    this.translate.setDefaultLang(DEFAULT_LANGUAGE);
    this.translate.use(DEFAULT_LANGUAGE);
    iconRegistry
      .addSvgIcon(
        'ic-brand',
        sanitizer.bypassSecurityTrustResourceUrl(
          `assets/icons/atoms-logos-brand-piso-51-logo.svg`
        )
      )
      .addSvgIcon(
        'ic-flags-uk',
        sanitizer.bypassSecurityTrustResourceUrl('assets/icons/ic-flags-uk.svg')
      )
      .addSvgIcon(
        'ic-flags-usa',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/icons/ic-flags-usa.svg'
        )
      )
      .addSvgIcon(
        'ic-socio-bag',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/icons/ic-socio-bag.svg'
        )
      )
      .addSvgIcon(
        'ic-socio-lock',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/icons/ic-socio-lock.svg'
        )
      )
      .addSvgIcon(
        'ic-flags-cuba',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/icons/ic-flags-cuba.svg'
        )
      )
      .addSvgIcon(
        'ic-flags-spain',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/icons/ic-flags-spain.svg'
        )
      )
      .addSvgIcon(
        'ic-flags-chile',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/icons/ic-flags-chile.svg'
        )
      )
      .addSvgIcon(
        'ic-control-minus',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/icons/ic-control-minus.svg'
        )
      )
      .addSvgIcon(
        'ic-flags-canada',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/icons/ic-flags-canada.svg'
        )
      )
      .addSvgIcon(
        'ic-flags-france',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/icons/ic-flags-france.svg'
        )
      )
      .addSvgIcon(
        'ic-flags-italia',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/icons/ic-flags-italia.svg'
        )
      )
      .addSvgIcon(
        'ic-flags-mexico',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/icons/ic-flags-mexico.svg'
        )
      )
      .addSvgIcon(
        'ic-flags-russia',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/icons/ic-flags-russia.svg'
        )
      )
      .addSvgIcon(
        'ic-flags-suecia',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/icons/ic-flags-suecia.svg'
        )
      )
      .addSvgIcon(
        'ic-socio-bottle',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/icons/ic-socio-bottle.svg'
        )
      )
      .addSvgIcon(
        'ic-flags-england',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/icons/ic-flags-england.svg'
        )
      )
      .addSvgIcon(
        'ic-flags-escocia',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/icons/ic-flags-escocia.svg'
        )
      )
      .addSvgIcon(
        'ic-flags-ireland',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/icons/ic-flags-ireland.svg'
        )
      )
      .addSvgIcon(
        'ic-socio-mi-cava',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/icons/ic-socio-mi-cava.svg'
        )
      )
      .addSvgIcon(
        'ic-flags-bulgaria',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/icons/ic-flags-bulgaria.svg'
        )
      )
      .addSvgIcon(
        'ic-brands-absolut',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/icons/ic-brands-absolut.svg'
        )
      )
      .addSvgIcon(
        'ic-brands-macallan',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/icons/ic-brands-macallan.svg'
        )
      )
      .addSvgIcon(
        'ic-control-nothing',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/icons/ic-control-nothing.svg'
        )
      )
      .addSvgIcon(
        'ic-brands-don-julio',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/icons/ic-brands-don-julio.svg'
        )
      )
      .addSvgIcon(
        'ic-brands-matusalem',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/icons/ic-brands-matusalem.svg'
        )
      )
      .addSvgIcon(
        'ic-empty-table-cava',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/icons/ic-empty-table-cava.svg'
        )
      )
      .addSvgIcon(
        'ic-brands-glenfiddich',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/icons/ic-brands-glenfiddich.svg'
        )
      )
      .addSvgIcon(
        'ic-brands-jose-cuervo',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/icons/ic-brands-jose-cuervo.svg'
        )
      )
      .addSvgIcon(
        'ic-control-purple-plus',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/icons/ic-control-purple-plus.svg'
        )
      )
      .addSvgIcon(
        'ic-control-yellow-exit',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/icons/ic-control-yellow-exit.svg'
        )
      )
      .addSvgIcon(
        'ic-control-yellow-menu',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/icons/ic-control-yellow-menu.svg'
        )
      )
      .addSvgIcon(
        'ic-control-white-logout',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/icons/ic-control-white-logout.svg'
        )
      )
      .addSvgIcon(
        'ic-control-yellow-dropd',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/icons/ic-control-yellow-dropd.svg'
        )
      )
      .addSvgIcon(
        'ic-control-purple-arrow',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/icons/ic-control-purple-arrow.svg'
        )
      )
      .addSvgIcon(
        'ic-brands-johnnie-walker',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/icons/ic-brands-johnnie-walker.svg'
        )
      )
      .addSvgIcon(
        'ic-control-yellow-search',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/icons/ic-control-yellow-search.svg'
        )
      )
      .addSvgIcon(
        'ic-brands-bombay-sapphire',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/icons/ic-brands-bombay-sapphire.svg'
        )
      )
      .addSvgIcon(
        'ic-control-purple-eye-open',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/icons/ic-control-purple-eye-open.svg'
        )
      )
      .addSvgIcon(
        'ic-control-white-arrow-back',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/icons/ic-control-white-arrow-back.svg'
        )
      )
      .addSvgIcon(
        'ic-control-purple-arrow-back',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/icons/ic-control-purple-arrow-back.svg'
        )
      )
      .addSvgIcon(
        'ic-control-purple-eye-closed',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/icons/ic-control-purple-eye-closed.svg'
        )
      )
      .addSvgIcon(
        'ic-control-yellow-arrow-back',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/icons/ic-control-yellow-arrow-back.svg'
        )
      )
      .addSvgIcon(
        'ic-logos-brand-cava-virtual_logo',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/icons/ic-logos-brand-cava-virtual_logo.svg'
        )
      )
      .addSvgIcon(
        'ic-bg',
        sanitizer.bypassSecurityTrustResourceUrl('assets/icons/BG_Bottles.svg')
      )
      .addSvgIcon(
        'ic-cava-virtual-logo',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/icons/Cava_Virtual_Logo.svg'
        )
      )
      .addSvgIcon(
        'ic-cava-akar-icons',
        sanitizer.bypassSecurityTrustResourceUrl(
          'assets/icons/akar-icons_circle-check-fill.svg'
        )
      );

    this.subscriptions.add(
      router.events.subscribe((val: any) => {
        if (val instanceof NavigationEnd) {
          if (location.path().includes('partners/search')) {
            this.waitress = true;
          } else {
            this.waitress = false;
          }
          if (location.path().includes('auth')) {
            this.empty = true;
          } else {
            this.empty = false;
          }
          if (location.path().includes('partners/login')) {
            this.partnersLogin = true;
          } else {
            this.partnersLogin = false;
          }
          if (location.path().includes('cava')) {
            this.cava = true;
          } else {
            this.cava = false;
          }
          if (location.path().includes('cava/cava-resume')) {
            this.empty = true;
            this.cava = true;
          }
          if (location.path().includes('cava/logout-qr')) {
            this.empty = false;
            this.cava = false;
          } else {
            this.empty = false;
          }
        }
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
