import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-close',
  templateUrl: './dialog-close.component.html',
  styleUrls: ['./dialog-close.component.scss']
})
export class DialogCloseComponent implements OnInit {

  constructor(
    public dialogCole: MatDialogRef<DialogCloseComponent>,
  ) { }

  ngOnInit(): void {
  }

  public closeModal(): void {
    this.dialogCole.close();
  }
}
