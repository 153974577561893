import { CommonModule } from '@angular/common';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

/** ANGULAR MATERIAL */
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';

/** GENERAL MODULES */
import {
  HttpClient,
  HttpClientJsonpModule,
  HttpClientModule,
} from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HeaderCavaComponent } from './headers/header-cava/header-cava.component';
import { HeaderEmptyComponent } from './headers/header-empty/header-empty.component';
import { HeaderLoginComponent } from './headers/header-login/header-login.component';
import { HeaderWaitressComponent } from './headers/header-waitress/header-waitress.component';
import { LoaderComponent } from './loader/loader.component';

/** INFINITE SCROLL */
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

/** MASK MODULES */
import { TextMaskModule } from 'angular2-text-mask';

/** LOADER */
import player from 'lottie-web';
import { LottieModule } from 'ngx-lottie';
import { DialogCloseComponent } from './dialog-close/dialog-close.component';
import { ModalOpenWineComponent } from './modal-open-wine/modal-open-wine.component';

export function playerFactory() {
  return player;
}

export function HttpLoaderFactory(http: HttpClient): any {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

// QR
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { InputCodeComponent } from './input-code/input-code.component';

@NgModule({
  declarations: [
    LoaderComponent,
    HeaderCavaComponent,
    HeaderLoginComponent,
    HeaderEmptyComponent,
    HeaderWaitressComponent,
    ModalOpenWineComponent,
    DialogCloseComponent,
    InputCodeComponent,
  ],
  imports: [
    FormsModule,
    CommonModule,
    LottieModule,
    MatIconModule,
    MatListModule,
    MatTabsModule,
    MatCardModule,
    TextMaskModule,
    MatBadgeModule,
    MatTableModule,
    MatInputModule,
    MatRadioModule,
    MatDialogModule,
    MatButtonModule,
    MatSelectModule,
    MatDividerModule,
    HttpClientModule,
    MatSnackBarModule,
    MatFormFieldModule,
    MatPaginatorModule,
    MatExpansionModule,
    MatDatepickerModule,
    MatNativeDateModule,
    ReactiveFormsModule,
    MatSlideToggleModule,
    InfiniteScrollModule,
    MatProgressBarModule,
    MatBottomSheetModule,
    HttpClientJsonpModule,
    ZXingScannerModule,
    LottieModule.forRoot({
      player: playerFactory,
    }),
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      isolate: false,
    }),
  ],
  exports: [
    FormsModule,

    MatIconModule,
    MatListModule,
    MatTabsModule,
    MatCardModule,
    TextMaskModule,
    MatBadgeModule,
    MatTableModule,
    MatInputModule,
    MatRadioModule,
    LoaderComponent,
    MatDialogModule,
    TranslateModule,
    MatButtonModule,
    MatSelectModule,
    MatDividerModule,
    HttpClientModule,
    HttpClientModule,
    MatSnackBarModule,
    MatPaginatorModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatNativeDateModule,
    ReactiveFormsModule,
    HeaderCavaComponent,
    HeaderLoginComponent,
    HeaderEmptyComponent,
    MatSlideToggleModule,
    InfiniteScrollModule,
    MatProgressBarModule,
    MatBottomSheetModule,
    HttpClientJsonpModule,
    ModalOpenWineComponent,
    HeaderWaitressComponent,
    ZXingScannerModule,
  ],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: 'es-MX',
    },
  ],
})
export class SharedModule {}
